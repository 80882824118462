.footer-cover {
    width: 100%;
    height: auto;
    margin:0 auto;
    background-image: url('../../public/media/Group.svg');
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: bottom 30% right 0%;
    .footer {
        max-width: 993px;
        height: auto;
        width: 100%;
        margin:0 auto;
        padding:100px 10px 40px 10px;
        .footer-menu {
            h2 {
                color: #21366e;
                font-size: 20px;
                padding-left: 10px;
            }
            ul {
                li {
                    list-style-type: none;
                    a {
                        display: block;
                        text-decoration: none;
                        font-size: 16px;
                        color: #b2b5b5;
                        padding:3px 10px;
                        &:hover {
                            color: #00bce3;
                        }
                    }
              
                    ul {
                        li {
                            display: inline;
                            a {
                                display: inline-block;
                                font-size: 20px;
                                padding:3px 10px;
                                margin:0 5px;
                                border-radius: 100%;
                                border: solid 1px #ddddf9;
                                background-color: #f6f6fe;
                                &:hover {
                                    box-shadow: 0 3px 10px 0 rgba(0, 188, 227, 0.35);
                                    background-color: #00bce3;
                                    color: #fff;
                                }
                            }
                        }
                    }
                }

            }
        }
        .social-media {
            form { 
                input {
                max-width: 380px;
                padding:20px;
                margin:3px;
                border-radius: 6px;
                box-shadow: 0 3px 15px 0 rgba(217, 217, 217, 0.16);
                border: solid 1px #dddddd;
                background-color: #ffffff;
                }
                button {
                    background-color: #00bce3;
                    border-radius: 6px;
                    padding:3px 15px;
                    font-size: 22px;
                    color: #ffffff;
                    max-height: 45px;
                    margin-top: 13px;
                    transition: 0.3s;
                    &:hover {
                        background-color: rgb(65, 199, 252);
                        color: #f9fbfc;
                    }
                    i {
                        transform: rotate(60deg);
                    }
                }
                    
            }
        }
    }

}

.input-group-append {
    z-index: 99;
    margin-left: -55px !important;
}
@media (max-width: 352px) {

.form-group ol, ul {
    padding-left: 22rem;
}

}

.list{
    padding-left: 0;
}


@media (max-width: 768px) {
    .footer-cover {
        
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom 0% right 30%;
    }
}


.copyrightstext {
    width: 100%;
    height: auto;
    margin: 0 auto;
    padding: 10px 10px;
    text-align: center;
    color: #b2b5b5;
}