@-webkit-keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}
@keyframes fadeIn {
    from { opacity: 0; }
      to { opacity: 1; }
}


.msgContent{
    // font-size: 16px;
    color: #21366e;
    -webkit-animation: fadeIn 3s;
    animation: fadeIn 3s;
    text-align: center;
    // opacity: 1;
    // transition: visibility 1s, opacity 3s linear;
 }
 .icon-cover {
    width: 100%;
    text-align: center;
 }

 .icon{
    color: #21366e;
    // text-align: center;
    //  width: 100% !important; 
    //  max-width: 108px;
     font-size: 100px;;
}
.demoForm{
    overflow: hidden !important;
    max-width: 1050px;
    height: auto;
    width: 100%;
    margin: 0 auto;
    border-radius: 22px;
    box-shadow: 0 3px 45px 0 #f5f5ff;
    // border: solid 8px #ffffff;
    // background-color: #f5f5ff;
    padding: 20px;
    position: relative;
    z-index: 999;
    .form-heading {
        width: 100%;
        height: auto;
        border-bottom: 1px solid #d1d2df;
        padding-bottom: 10px;
        text-align: center;
        h1 {
        font-size: 24px;
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.2;
        margin: 0;
        color: #21366e;
        }
    }
    .textInput{
         
        .textField{
           text-align: left;
           margin:5px auto;
           padding: 2px;
           padding-top: 13px;
           padding-bottom: 7px;
           position: relative;
           max-width: 100%;
           width: 100%;
           @media (max-width: 768px) {
            margin-top: 24px;
             width: 100%;
             max-width: 100%;
             
    /* padding-left: 25px; */
            margin-left: 23px;

          }

        }
    }
    .MuiOutlinedInput-input {
    padding: 14px 14px;
}

.MuiFormHelperText-contained {
    position: absolute;
    bottom: -13px;
}
    .demoSubmitButton{
        text-decoration: none;
        max-width: 220px;
        width: 100%;
        height: auto;
        background-color: #00bce3;
        display: block;
        text-align: center;
        padding: 15px 0;
        color: #fff;
        text-transform: uppercase;
        font-size: 16px;
        border-radius: 5px;
        @media (max-width: 768px) {
            margin-top: 24px;
            width: 74%;
            // margin-left: 50px;
            // margin-top: 20px;
          }

    }
    .demoSubmitButton[disabled] {
        border: 1px solid #6d8c76;
        background-color: #97cca5!important;
      }

    .hr-sect {
        display: flex;
        flex-basis: 100%;
        align-items: center;
        color: rgba(0, 0, 0, 0.35);
        font-size: 12px;
        margin: 2px 7px;
    }
    .hr-sect::before,
    .hr-sect::after {
        content: "";
        flex-grow: 1;
        background: rgba(0, 0, 0, 0.35);
        height: 1px;
        font-size: 0px;
        line-height: 0px;
        margin: 0px 16px;
    } 
  
  }  
 .demoDialog .MuiDialog-paperWidthSm {
    max-width: 55vw;
    padding: 15px;
    // height: 93vh; 
    max-height: 100%;
    border-radius: 16px;
    width: 100%;
    text-align: left;
    justify-content: center;
}
.demoForm .demoSubmitButton {
    // margin-left: 150px;
    // margin-top: 10px;
    margin: 0 auto;
    opacity: 1;
    background-color: #00bce3;
}
.demoForm .demoSubmitButton[disabled] {
 border: none;
    background-color: #00bce3 !important;
    color: #fff !important;
    opacity: .6;
}

.formside-content {
    display: block;
    h1 {}
    p {
        margin-top: 15px;
        font-size: 16px;
        color: #b2b5b5;
    }
    ul {
        li {
            display:block;
            padding:15px 10px 15px 35px;
            position: relative;
            max-width: 80%;
            width: 100%;
            // text-align: center;
            font-size: 16px;
            color: #21366e;
            list-style-type: none;
            span {
                text-align: center;
                &::before {
                    content: "\2713";
                    position: absolute;
                    top: 15px;
                    left: 0px;
                    width: 25px;
                    height: 25px;
                    background-color: rgba(0, 188, 227, 0.11);
                    border-radius: 100%;
                    border: 1px solid #00bce3;
                }
            }
        }
    }
}
 @media (max-width: 768px) {
       
        .demoDialog .MuiDialog-paperWidthSm {
        margin-top: 1px;
        width: 100%;
        
    max-width:  90vw;
    padding: 22px;
    // height: 93vh;
    max-height: 100%;
    border-radius: 16px;
    width: 100%;
    text-align: left;
    justify-content: center;
        }
        .demoForm .textInput .textField {
    text-align: left;
    margin: 8px;
    padding: 7px;
     padding-top: 5px; 
    padding-bottom: 1px;
    max-width: 100%;
    width: 100%;
}
.demoForm .MuiOutlinedInput-input {
    // padding: 9px 6px;
    // text-indent: 5px;
}
.demoForm .demoSubmitButton {
    
}
}


@media (max-width: 1200px) {
    .formside-content {
        display: none;
    }
}